<template>
    <div class="page">
        <div class="page_header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>了解威有</el-breadcrumb-item>
                <el-breadcrumb-item>荣誉资质</el-breadcrumb-item>
                <el-breadcrumb-item>全部荣誉</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="page_headerSecondary">
            <div></div>
            <div class="_add">
                <button @click="openAdd">添加荣誉</button>
            </div>
        </div>
        <!-- 列表 -->
        <div class="page_content">
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column prop="title" align="center" label="名称"> </el-table-column>
                <el-table-column prop="pl" align="center" label="隶属分类"> </el-table-column>
                <el-table-column prop="status" align="center" label="状态">
                    <template slot-scope="{ row }">
                        <span :style="{ color: row.status ? '#b66dff' : '#fe7c96' }">{{ row.status ? "显示" : "隐藏" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="" align="center" label="操作">
                    <template slot-scope="{ row }">
                        <div class="btns">
                            <div class="_operate">
                                <button @click="openSet(row)">编辑</button>
                            </div>
                            <div class="_delete">
                                <button @click="del(row)">删除</button>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!-- 添加或编辑 -->
        <el-dialog
            :title="`${form.id ? '编辑' : '添加'}荣誉`"
            :visible.sync="dialogShow"
            width="50%"
            :show-close="false"
            :before-close="bfc"
        >
            <el-form :model="form" label-width="80px">
                <el-form-item label="名称">
                    <el-input v-model.trim="form.title"></el-input>
                </el-form-item>
                <el-form-item label="查询证伪">
                    <el-input v-model.trim="form.url"></el-input>
                </el-form-item>
                <el-form-item label="一级分类">
                    <el-select v-model="form.pid" clearable placeholder="选择一级分类">
                        <el-option v-for="el in categoryList" :key="el.id" :label="el.title" :value="el.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态">
                    <el-switch
                        class="sw"
                        v-model="form.status"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                        active-text="显示"
                        inactive-text="隐藏"
                        :active-value="1"
                        :inactive-value="0"
                    ></el-switch>
                </el-form-item>
                <el-form-item label="主图">
                    <uploadImgVue :src="form.image" @removeSrc="form.image = ''" ref="image"></uploadImgVue>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogShow = false">取 消</el-button>
                <el-button type="primary" @click="yes">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import uploadImgVue from "../../customs/uploadImg.vue";
import QueryString from "qs";

export default {
    components: { uploadImgVue },
    data() {
        return {
            tableData: [],
            categoryList: [],
            form: {},
            dialogShow: false,
        };
    },
    async created() {
        this.categoryList = await this.getCategory();
        this.tableData = await this.getData();
        // 添加父分类名字
        this.tableData.forEach(e => {
            e.pl = this.categoryList.find(el => el.id == e.pid).title;
        });
    },
    methods: {
        async getData() {
            let { data } = await axios.get("/api/honor/show");
            return data;
        },
        // 获取一级
        async getCategory() {
            let { data } = await axios.get("/api/honor/categoryshow ");
            return data;
        },
        // 打开添加
        openAdd() {
            this.form = {
                title: "",
                pid: "",
                status: 1,
                image: "",
            };
            this.dialogShow = true;
        },
        // 打开编辑
        openSet(row) {
            this.form = { ...row };
            this.dialogShow = true;
        },
        // 确定
        async yes() {
            this.form.image = this.$refs.image.url;
            let form = { ...this.form };
            if (form.id) {
                // 编辑
                var { data } = await axios.put("/api/honor/update", QueryString.stringify(form));
            } else {
                // 添加
                var { data } = await axios.post("/api/honor/add", QueryString.stringify(form));
            }
            if (data.code != 200) {
                this.$message.error(data.msg);
                return;
            } else {
                this.$message.success(data.msg);
            }
            this.tableData = await this.getData();
            this.dialogShow = false;
        },
        async del({ id }) {
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => true);
            if (!s) return;
            let { data } = await axios.delete("/api/honor/del", { params: { id } });
            if (data.code != 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.tableData = await this.getData();
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_headerSecondary {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
        .btns {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}
.sw {
    /deep/.el-switch__label.is-active {
        color: #7781f1 !important;
    }
}
</style>
